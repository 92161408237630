<template>
  <div>
    <!--begin: Head -->

    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      style="background-color: black;"
    >
      <div class="kt-user-card__avatar">
        <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
        >
          {{ user.firstName | firstLetter }}</span
        >
      </div>
      <div class="kt-user-card__name">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <router-link :to="{ name: 'profile' }">
        <v-btn
          icon
          title="Manage account"
          style="background-color: white;"
          class="float-right"
        >
          <v-icon>mdi-settings</v-icon>
        </v-btn>
      </router-link>
    </div>

    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <!--<a href="#" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">My Profile</div>
          <div class="kt-notification__item-time">
            Account settings and more
          </div>
        </div>
      </a>-->
      <div class="kt-notification__custom kt-space-between">
        <a
          href="#"
          v-on:click="onLogout()"
          class="btn btn-label btn-label-brand btn-sm btn-bold"
          >Sign Out</a
        >
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import auth, { LOGOUT } from "@/store/auth.module";

export default {
  name: "KTDropdownUser",

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {
    user() {
      return auth.state.user;
    }
  }
};
</script>
