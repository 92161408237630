import menuConfig from "@/common/config/menu.config.js";

const Menu = {
  items: menuConfig,

  // eslint-disable-next-line no-unused-vars
  get(role, items) {
    let toReturn = [];
    Object.entries(items).forEach(([key, val]) => {
      if (typeof val.submenu !== "undefined") {
        val.submenu = this.get(role, items[key].submenu);
      }

      let conditions =
        typeof val.roles === "undefined" ||
        val.roles.some(r => role.includes(r));

      if (val.condition) {
        conditions =
          (typeof val.roles === "undefined" ||
            val.roles.some(r => role.includes(r))) &&
          eval(val.condition);
      }

      if (conditions) {
        toReturn.push(val);
      }
    });

    return toReturn;
  },

  getHeader(role) {
    return this.get(role, this.items.header.items);
  },

  getAside(role, companyRoles) {
    let items = this.items.aside.items.filter(item => {
      if (typeof item.companyRoles === "undefined") {
        return true;
      } else if (companyRoles) {
        let shouldBeInMenu = false;
        companyRoles.forEach(companyRole => {
          if (item.companyRoles.includes(companyRole)) {
            shouldBeInMenu = true;
          }
        });
        return shouldBeInMenu;
      } else {
        return false;
      }
    });
    return this.get(role, items);
  }
};
export default Menu;
