<template>
  <li
    class="kt-menu__item"
    aria-haspopup="true"
    :class="{
      'kt-menu__item--submenu': menu.submenu,
      'kt-menu__item--open': activeMenu(menu)
    }"
    :data-ktmenu-submenu-toggle="submenuToggle(menu)"
  >
    <KTMenuItemText v-bind:menu="menu"></KTMenuItemText>
    <KTMenuSubmenu
      v-if="menu.submenu"
      v-bind:submenu="menu.submenu"
      v-bind:parentMenu="menu"
    ></KTMenuSubmenu>
  </li>
</template>

<script>
import KTMenuItemText from "@/views/theme/aside/MenuItemText.vue";
import KTMenuSubmenu from "@/views/theme/aside/MenuSubmenu.vue";

export default {
  name: "KTMenuItem",
  components: {
    KTMenuItemText,
    KTMenuSubmenu
  },
  props: {
    menu: Object
  },
  methods: {
    activeMenu(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(conf => {
        if (conf.submenu) {
          return this.activeMenu(conf.submenu);
        }
        if (conf.page) {
          // current path starts with this path string
          let path = this.$route.path;
          if (this.$route.params && this.$route.params.id) {
            path = path.replace("/" + this.$route.params.id, "");
          }
          return (
            this.$route.path === `/${conf.page}` ||
            (conf.childPages && conf.childPages.indexOf(path) > -1)
          );
        }
      });
    },
    /**
     * Get submenu toggle type
     * @param menu
     * @returns {string|*}
     */
    submenuToggle(menu) {
      if (menu.hasOwnProperty("toggle")) {
        return menu.toggle;
      }
      return "hover";
    }
  }
};
</script>

<style>
.kt-aside-dark
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading,
.kt-aside-dark
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link {
  background: rgb(255, 72, 103);
  background: linear-gradient(
    47deg,
    rgba(255, 72, 103, 1) 0%,
    rgba(255, 150, 29, 1) 25%,
    rgba(255, 240, 31, 1) 100%
  );
  padding: 1em 1.5em;
  border-radius: 40px;
  margin-left: 5px;
  margin-right: 15px;
}

.kt-aside-dark
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-aside-dark
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-text {
  color: black;
}
</style>
