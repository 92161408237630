<template>
  <ul class="kt-menu__nav pt-0">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-if="!menu.section" :menu="menu" :key="i"></KTMenuItem>
      <KTMenuSection v-if="menu.section" :menu="menu" :key="i"></KTMenuSection>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import Menu from "@/common/menu.service";

export default {
  name: "KTAsideMenu",
  components: {
    KTMenuItem,
    KTMenuSection
  },
  methods: {},
  computed: {
    ...mapGetters(["getRole", "getCompanyRoles", "currentUser"]),

    getRolesV2() {
      return this.currentUser.roles;
    },

    menuItems() {
      return Menu.getAside(
        [...this.getRolesV2, this.getRole],
        this.getCompanyRoles
      );
    }
  }
};
</script>
