<template>
  <li class="kt-menu__section d-flex align-items-center">
    <h4 class="kt-menu__section-text" style="text-transform: initial;">
      {{ menu.translate ? $t(menu.translate) : menu.title }}
      <span
        v-if="menu.isNew"
        class="border border-danger rounded text-danger px-2 ml-2"
        style="font-size: 10px;"
        >{{ $tc("MENU.NEW") }}</span
      >
    </h4>
    <i class="kt-menu__section-icon flaticon-more-v2"></i>
  </li>
</template>

<script>
export default {
  name: "KTMenuSection",
  components: {},
  props: {
    menu: Object
  }
};
</script>
