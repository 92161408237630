<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <Ribbon />
    <KTHeaderMobile></KTHeaderMobile>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <KTAside v-if="asideEnabled"></KTAside>
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <KTHeader
          v-bind:breadcrumbs="breadcrumbs"
          v-bind:title="pageTitle"
          v-bind:pageCategory="pageCategory"
        >
        </KTHeader>
        <div
          class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          id="kt_content"
        >
          <v-app>
            <div
              class="kt-grid__item kt-grid__item--fluid"
              v-bind:class="{ 'kt-container--fluid': contentFluid }"
            >
              <transition name="fade-in-up">
                <router-view></router-view>
              </transition>
            </div>
          </v-app>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/views/theme/aside/Aside.vue";
import KTHeader from "@/views/theme/header/Header.vue";
import KTHeaderMobile from "@/views/theme/header/HeaderMobile.vue";
import KTFooter from "@/views/theme/footer/Footer.vue";
import HtmlClass from "@/common/htmlclass.service";
import KTScrollTop from "@/views/partials/layout/ScrollTop";
import Loader from "@/views/partials/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/store/htmlclass.module.js";
import Ribbon from "@/components/ribbonPreprod";

export default {
  name: "Base",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTScrollTop,
    Loader,
    Ribbon
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");
    this.checkUserAuthenticated();

    // initialize html element classes
    HtmlClass.init();
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
    }, 1000);
  },
  methods: {
    checkUserAuthenticated() {
      // check if current user is authenticated
      const roles = [...this.getRolesV2, this.getRole];
      if (
        !this.isAuthenticated ||
        !this.$route.meta.roles.some(r => roles.includes(r))
      ) {
        this.$router.push({ name: "login" });
      }
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getRole",
      "breadcrumbs",
      "pageTitle",
      "pageCategory",
      "layoutConfig",
      "currentUser"
    ]),

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    getRolesV2() {
      return this.currentUser.roles;
    }
  },
  //Safer to use the watcher, for checking roles
  watch: {
    $route() {
      this.checkUserAuthenticated();
    }
  }
};
</script>
