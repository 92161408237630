<template>
  <component
    :is="isExternal ? 'a' : 'router-link'"
    :href="isExternal ? link() : undefined"
    :to="!isExternal ? link() : undefined"
    :target="isExternal ? '_blank' : '_self'"
    :key="$route.fullPath"
    :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link"
  >
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <span
      v-if="menu.iconSVG"
      class="kt-menu__link-icon kt-svg-icon kt-svg-icon-lg"
    >
      <inline-svg :src="menu.iconSVG" />
    </span>
    <i
      v-if="!menu.icon && !menu.root"
      class="kt-menu__link-bullet"
      :class="bullet()"
      ><span></span
    ></i>
    <span class="kt-menu__link-text"
      >{{ menu.translate ? $t(menu.translate) : menu.title }}
      <!-- border-info -->
      <span
        v-if="menu.isNew"
        class="border border-danger rounded text-danger px-2 ml-2"
        style="font-size: 10px"
        >{{ $tc("MENU.NEW") }}</span
      ></span
    >

    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import auth from "@/store/auth.module";
import JwtService from "@/common/jwt.service";

export default {
  name: "KTMenuItemText",
  data() {
    return {
      user: auth.state.user
    };
  },
  components: {},
  props: {
    menu: Object,
    parentMenu: Object
  },
  computed: {
    ...mapGetters(["isAdmin"]),

    isExternal() {
      return this.menu?.external || false;
    }
  },
  methods: {
    /**
     * Get bullet class from config
     * @returns {string} class name
     */
    bullet: function() {
      // get the default bullet
      if (!this.menu.bullet) {
        this.menu.bullet = "dot";
      }
      // bullet class name
      return `kt-menu__link-bullet--${this.menu.bullet}`;
    },
    /**
     * Get current menu link path
     * @returns {{}|{path: string}}
     */
    link: function() {
      // check if no link in this menu item
      let link = this.menu.page;

      if (!link) {
        return {};
      }

      let queryString = "";
      let query = this.menu.query;

      if (query && "active" in this.user) {
        queryString = "/" + eval(query);
      }

      if (this.isAdmin && this.menu.pageIsAdmin) {
        link = this.menu.pageIsAdmin;
      }

      if ("external" in this.menu && this.menu.external) {
        return `${this.menu.redirectTo}?token=${JwtService.getToken()}`;
      }

      // pass the link prepend with slash
      return { path: `/${link + queryString}` };
    }
  }
};
</script>
