<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-bind:menu="menu" :key="i"></KTMenuItem>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import { mapGetters } from "vuex";
import Menu from "@/common/menu.service";

export default {
  name: "KTHeaderMenu",
  components: {
    KTMenuItem
  },
  computed: {
    ...mapGetters(["getRole", "getCompanyRoles"]),

    menuItems() {
      return Menu.getHeader(this.getRole);
    }
  }
};
</script>
