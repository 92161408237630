<template>
  <div
    id="kt_header_mobile"
    class="kt-header-mobile"
    v-bind:class="headerClasses"
  >
    <div class="kt-header-mobile__toolbar">
      <button
        class="kt-header-mobile__toggler kt-header-mobile__toggler--right"
        id="kt_aside_mobile_toggler"
      >
        <span></span>
      </button>
    </div>
    <div class="kt-header-mobile__logo">
      <a href="/">
        <img alt="Logo" :src="headerLogo" />
      </a>
    </div>
    <div class="kt-header-mobile__toolbar">
      <div class="kt-header__topbar-item kt-header__topbar-item--user">
        <div
          class="kt-header__topbar-wrapper"
          id="kt_user_toggle"
          data-toggle="dropdown"
        >
          <div class="kt-header__topbar-user grey-text-color">
            <span class="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
            <span
              class="kt-header__topbar-username kt-hidden-mobile font-weight-bold"
            >
              {{ user.firstName }}
            </span>
            <span
              class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            >
              {{ user.firstName | firstLetter }}</span
            >
          </div>
        </div>
        <div
          class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
          v-on:click.stop
        >
          <KTDropdownUser></KTDropdownUser>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.kt-brand-dark .kt-header-mobile {
  background-color: black !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import auth from "@/store/auth.module";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";

export default {
  name: "KTHeaderMobile",
  components: {
    KTDropdownUser
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    user() {
      return auth.state.user;
    },
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.light");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>

<style scoped>
.grey-text-color {
  color: #959cb6;
}
</style>
