<template>
  <div v-if="isPreprod" class="ribbon ribbon-top-left">
    <span class="text-environement">PREPROD</span>
  </div>
</template>

<script>
export default {
  computed: {
    isPreprod() {
      return location.host.includes("preprod");
    }
  }
};
</script>

<style scoped lang="scss">
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
}

.ribbon .text-environement {
  position: absolute;
  width: 225px;
  padding: 8px 0;
  background-color: red;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.ribbon-top-left .text-environement {
  right: -45px;
  left: -5px;
  top: 80px;
  transform: rotate(-45deg);
}
</style>
