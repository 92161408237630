import { ROLES } from "@/common/config/acl/roles";
import config from "@/common/config";

const URL_HOSTING_PLATFORM = config.items.hosting_platform;

const menuConfig = {
  header: {
    self: {
      display: false
    },
    items: []
  },
  aside: {
    self: {},
    items: [
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER
        ],
        title: "Analytics",
        root: true,
        section: "Analytics",
        translate: "MENU.ANALYTICS"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER
        ],
        title: "Dashboard",
        root: true,
        iconSVG: "assets/media/icons/svg/Communication/RSS.svg",
        page: "dashboard",
        translate: "MENU.AUDIENCE",
        bullet: "dot"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER
        ],
        title: "Followers",
        root: true,
        iconSVG: "assets/media/icons/svg/Communication/Group.svg",
        page: "followers",
        translate: "MENU.FOLLOWERS",
        bullet: "dot"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
        title: "Conversion",
        root: true,
        iconSVG: "assets/media/icons/svg/Media/Equalizer.svg",
        page: "dashboard/conversion",
        translate: "DASHBOARD_CONVERSION.MENU",
        bullet: "dot"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER],
        title: "AdStudio",
        root: true,
        section: "AdStudio",
        translate: "MENU.ADSTUDIO"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER],
        title: "Native Ad Delivery",
        root: true,
        iconSVG: "assets/media/icons/svg/Text/Bullet-list.svg",
        page: "adstudio/delivery/orders",
        translate: "Native Ad Delivery",
        bullet: "dot"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER],
        title: "Native Ad Monitoring",
        root: true,
        iconSVG: "assets/media/icons/svg/Media/Equalizer.svg",
        page: "adstudio/monitoring",
        translate: "Native Ad Monitoring",
        bullet: "dot"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
        title: "Discovery",
        isNew: true,
        root: true,
        section: "Discovery",
        translate: "MENU.DISCOVERY.SECTION"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
        title: "Discovery",
        root: true,
        iconSVG: "assets/media/icons/svg/Media/Equalizer.svg",
        page: "discovery/monitoring/pages",
        translate: "MENU.DISCOVERY.MONITORING.PAGES",
        bullet: "dot"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
        title: "Discovery",
        root: true,
        iconSVG: "assets/media/icons/svg/Media/Media-library1.svg",
        page: "discovery/simulator",
        translate: "MENU.DISCOVERY.SIMULATOR",
        bullet: "dot"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER
        ],
        title: "Revenue",
        root: true,
        section: "revenue",
        translate: "MENU.REVENUS"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER
        ],
        title: "Native revenue",
        root: true,
        iconSVG: "assets/media/icons/svg/Shopping/Euro.svg",
        page: "revenue",
        translate: "MENU.MANAGEREVENUS",
        bullet: "dot"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER
        ],
        title: "Account",
        root: true,
        section: "account",
        translate: "MENU.ACCOUNT"
      },
      {
        roles: [ROLES.BBB_ADMIN, ROLES.HOSTING_MANAGER, ROLES.HOSTING_USER],
        title: "Hosting",
        isNew: true,
        root: true,
        iconSVG: "assets/media/icons/svg/Home/Commode1.svg",
        page: "hosting",
        redirectTo: URL_HOSTING_PLATFORM,
        external: true,
        translate: "MENU.ADVANCED_SETTINGS.HOSTING",
        bullet: "dot"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER
        ],
        title: "Podcasts",
        root: true,
        iconSVG: "assets/media/icons/svg/Devices/Mic.svg",
        page: "podcasts",
        translate: "MENU.PODCASTS",
        bullet: "dot"
      },
      {
        title: "Domains",
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN
        ],
        root: true,
        iconSVG: "assets/media/icons/svg/Text/Bullet-list.svg",
        page: "settings/domains",
        translate: "MENU.ADVANCED_SETTINGS.DOMAINS",
        bullet: "dot"
      },
      {
        title: "My company",
        root: true,
        iconSVG: "assets/media/icons/svg/Home/Building.svg",
        page: "company",
        query: "this.user.company.id",
        translate: "MENU.ADVANCED_SETTINGS.MY_COMPANY",
        bullet: "dot"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER
        ],
        title: "Help",
        root: true,
        section: "Help",
        translate: "MENU.HELP.SECTION"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER
        ],
        title: "FAQ",
        root: true,
        iconSVG: "assets/media/icons/svg/Code/Question-circle.svg",
        page: "faq",
        translate: "MENU.HELP.FAQ"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER
        ],
        title: "Admin",
        root: true,
        section: "AdStudio"
      },
      {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER
        ],
        title: "Configuration",
        root: true,
        icon: "flaticon-cogwheel",
        translate: "MENU.CONFIGURATION.MAIN",
        bullet: "line",
        submenu: [
          {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER
            ],
            title: "Companies",
            translate: "MENU.CONFIGURATION.COMPANIES",
            page: "admin/companies"
          },
          {
            roles: [ROLES.BBB_ADMIN],
            title: "Podcasts",
            translate: "MENU.CONFIGURATION.PODCASTS",
            page: "admin/podcasts"
          },
          {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
            title: "Categories",
            translate: "MENU.CONFIGURATION.CATEGORIES",
            page: "admin/categories",
            childPages: ["/admin/category"]
          }
        ]
      }
    ]
  }
};

export default menuConfig;
