<template>
  <!-- begin::Page loader -->
  <div class="kt-page-loader kt-page-loader--logo">
    <img alt="Logo" :src="logo" />
    <b-spinner
      class="mt-3"
      style="color: #FF961D;"
      label="Spinning"
    ></b-spinner>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "Loader",
  props: {
    logo: String
  }
};
</script>
